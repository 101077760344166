
// TEXTColor
$folio-color-corePrimary: #8C8C8E;
$folio-color-coreSecondary: white;
$folio-color-coreTertiary: #20202A;


//BrandMainColor
$folio-color-main: #ffc107;


//BackgroundColor
$folio-color-backgroundPrimary: #1E1E28;
$folio-color-backgroundSecondary: #20202A;
$folio-color-backgroundTertiary: #262636;
$folio-color-backgroundSub: #242430;
$folio-color-backgroundComplimentary: #2D2D39;
$folio-color-backgroundComplimentaryContact: #2C2C38;

