@import '../../ColorTheme/color.scss';

.CompCode {
    font-family: 'Courier New', Courier, monospace;
    font-weight: 800;
    color: white;
    text-wrap: wrap;
    .code{
        color: $folio-color-main
    }
}