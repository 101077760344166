@import '../../../../ColorTheme/color.scss';

.About {
    background: $folio-color-backgroundSub;
    padding: 2rem 0;
    width: 100%;
    
    .avatarContainer{
        width: 90px;
        height: 90px;
        margin: 0 auto;
        border-radius: 50%;
        position: relative;
        margin-bottom: 15px;
        img{
            width: inherit;
            height: inherit;
            border-radius: 50%;
        }
    }
    .name_job{
        text-align: center;
        color: $folio-color-corePrimary;
        h5{
            color: $folio-color-coreSecondary;
        };
        p:last-child{
            margin-top: -15px;
        }
    }
    .lampContainer{
        position: absolute;
        width: 99%;
        margin-top: -55px;
        z-index: 9999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .lamp{
        border-radius: 50%;
        margin: 10px;
        margin-left: 90px;
        height: 15px;
        width: 15px;
        transform: scale(1);
        background: $folio-color-main;
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
        animation: pulse 1s infinite;
    }
    
    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
        }
        
        70% {
            transform: scale(1);
            box-shadow: 0 0 0 5px rgba(255, 121, 63, 0);
        }
        
        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
        }
    }
    
    .availability{
      display: none;
    }
    
    .lamp:hover + .availability{
        display: block;
        font-size: 10px;
        position: absolute;
        left: 100px;
        width: fit-content;
    
    }
    .socialConnection{
        display: none;
        padding-top: 2rem;
        margin: auto;
        // width: 55%;
      }
}

// @media (max-width: 600px) { 
//     .About {
//         bottom: 55px;
//       .socialConnection{
//         display: block;
//       }
//     }
    
//   }

//   @media (min-width: 1033px) { 
//     .closeIconContainer {
//         display: none;
        
//     }
    
//   }