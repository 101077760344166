@import '../../ColorTheme/color.scss';

.CompContactForm {
    padding: 16px;
    background-color: $folio-color-backgroundComplimentaryContact;

    .formFieldContainer{
        display: flex;
        flex-direction: column;
        p{
            margin: 0;
            margin-top: 2px;
            font-size: 12px;
            color: salmon;
        }
    }
    input,
    input:focus,
    input:active{
        background-color: $folio-color-backgroundSub !important;
        color: $folio-color-corePrimary;
        border: none;
        padding: 14.7px 14px;
    }
    input:-webkit-autofill {
        background-color: $folio-color-backgroundSub !important;
        color: $folio-color-corePrimary;
    }
    
    .formContainer{
        background-color: $folio-color-backgroundSub !important;
        color: $folio-color-corePrimary;
        border-color: $folio-color-backgroundSub !important;
        display: flex;
    }
    .contactInfoIcon{
        width: 3rem;
        color: $folio-color-corePrimary;
        background-color: $folio-color-backgroundSecondary;
        text-align: center;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
    }
    .messageIcon{
        display: flex;
        height: inherit;
        justify-content: center;
        align-items: flex-start;
        span{
            padding-top: 12.5px
        }
    }
    textarea{
        color: $folio-color-corePrimary !important;
    }
    .sendMessage {
        border: none;
        margin: 4rem 0 3rem 0;
        button{
            border-radius: 0;
            background-color: $folio-color-main;
            font-weight: 700;
            color: $folio-color-coreTertiary;
        }
    }
}