@import '../../ColorTheme/color.scss';

.CompCircularProgressBar {
    color: $folio-color-coreSecondary;
    font-weight: 400;
    .skillSetContainer{
        width: 45px !important;
        height: 45px !important;
        color: $folio-color-main;
    }
    .skillSetContainerText{
        width: 45px !important;
        font-size: 12px;
    }
    span{
        color: $folio-color-main;
        font-weight: 700;
        font-size: 22px;
        margin-right: 5px;
    }
}