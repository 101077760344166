@import '../../../ColorTheme/color.scss';

.CompJumbotron {
    position: relative;
    margin-top: 4rem;
    margin-bottom: 4rem;
    background-image: url("../../../assets/images/bg.jpeg"); 
    background-color: #cccccc; 
    height: 400px; 
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    .jumbotronComponent1{
        content: "";
        position: absolute;
        z-index: 0;
        top: -4rem;
        width: 100%;
        height: 4rem;
        box-shadow: rgba(15, 15, 20, 0.2) 0px 3px 8px 0px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        background: rgba(30, 30, 40, 0.88);
    }
    .jumbotronComponent3{
        position: relative;
        display: flex;
        justify-content: center;
        padding: 60px;
        height: 400px;
        background-image: linear-gradient(90deg, rgba(45, 45, 58, 0.9) 15%, rgba(45, 45, 58, 0.7) 50%, rgba(43, 43, 53, 0.7) 100%);
        color: #cccccc;
        .jumbotronContentContainer{
            .jumbotronHead{
                max-width: 330px;
                text-align: center;
                font-weight: 900;
                margin-bottom: 1rem;
                color: white;
                width: 100%;
            }
            .contactNow {
                background-color: $folio-color-main;
                border: none;
                margin-top: 1.35rem;
                font-weight: 700;
                padding: 0.7rem;
                border-radius: 3px;
                a{
                    text-decoration: none;
                    color: $folio-color-coreTertiary;
                }
            }
            button:focus{
                outline: none;
            }
        }
    }

}   

// MOBILE
@media (max-width: 1033px) { 
    .CompJumbotron {
        margin-top: 2rem;
    }
}
@media (max-width: 1200px) { 
    .CompJumbotron {
        margin-top: 2rem;
    }
}
@media (max-width: 600px) { 
    .CompJumbotron {
        margin-top: 1rem;
       
    }
}